import SourceProductCard from 'SourceComponent/ProductCard/ProductCard.component';
import { ReactElement } from 'Type/Common.type';

import './ProductCard.override.style';

export * from 'SourceComponent/ProductCard/ProductCard.component';

/** @namespace Pwa/Component/ProductCard/Component */
export class ProductCardComponent extends SourceProductCard {
    renderProductActions(): ReactElement {
        return (
            <div block="ProductCard" elem="ProductActions">
                { this.renderProductCardWishlistButton() }
            </div>
        );
    }

    renderCardContent(): ReactElement {
        const { renderContent, product: { name } } = this.props;

        if (renderContent) {
            return renderContent(this.contentObject);
        }

        return (
            this.renderCardLinkWrapper((
                <div block="ProductCard" elem="LinkInnerWrapper" mods={ { loaded: !!name } }>
                    <div block="ProductCard" elem="FigureReview">
                        <figure block="ProductCard" elem="Figure">
                            { this.renderPicture() }
                        </figure>
                    </div>
                    <div block="ProductCard" elem="Content">
                        { this.renderPrice() }
                        { this.renderName(true) }
                    </div>
                </div>
            ))
        );
    }
}

export default ProductCardComponent;

import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import SourceProduct from 'SourceComponent/Product/Product.component';
import { ReactElement } from 'Type/Common.type';

export * from 'SourceComponent/Product/Product.component';

/** @namespace Pwa/Component/Product/Component */
export class ProductComponent extends SourceProduct {
    renderName(header = true, dynamic = false): ReactElement {
        const { product: { name }, productName } = this.props;
        const nameToRender = dynamic ? productName : name;

        if (!header) {
            return (
                <p block={ this.className } elem="Name">
                    <TextPlaceholder content={ nameToRender } length={ TextPlaceHolderLength.MEDIUM } />
                </p>
            );
        }

        return (
            <h2 block={ this.className } elem="Title" itemProp="name">
                <TextPlaceholder content={ nameToRender } length={ TextPlaceHolderLength.MEDIUM } />
            </h2>
        );
    }
}

export default ProductComponent;
